import classNames from 'classnames/bind';
import String from 'components/String/String';
import { BusinessEntityTypes, LLCTaxClassification, Disclosures } from 'models/enums/index';
import { formatString } from 'strings';
import styles from './listOfFormObjects.module.css';

const cx = classNames.bind(styles);

export const listOfAppliances = [
  'Air Conditioner',
  'Alarm System',
  'Baseboard',
  'Barbeque',
  'Bed Bugs',
  'Bees & Wasps',
  'Blinds',
  'Breaker Box',
  'Cabinets, Drawers & Shelving',
  'Carbon Monoxide Detector',
  'Carpet',
  'Fan',
  'Ceiling',
  'Chimney',
  'Cleaning',
  'Pests',
  'Countertop',
  'Cupboards & Closets',
  'Curtains',
  'Decking',
  'Dishwasher',
  'Drain',
  'Driveway',
  'Dryer',
  'Exterior Doors & Gates',
  'Faucet',
  'Faucets & Garden Hoses',
  'Fencing',
  'Fire Sprinkler',
  'Firepit',
  'Fireplace',
  'Floor Surface',
  'Floor, Wall & Ceiling Vents',
  'Freezer',
  'Refrigerator',
  'Furnace',
  'Garage',
  'Garbage Disposal',
  'Gas Supply',
  'Gutter',
  'Hood Vent',
  'Interior Door',
  'Interior Wall',
  'Irrigation System',
  'Keys, Cards & Fobs',
  'Lawn',
  'Light Switch',
  'Lighting',
  'Lock',
  'Microwave',
  'Oven',
  'Paint',
  'Power Outlet',
  'Raccoons',
  'Railings & Handrails',
  'Rodents',
  'Roof',
  'Satellites & Antennas',
  'Sink',
  'Skunks',
  'Smoke Detector',
  'Solar Panel',
  'Stove',
  'Termites',
  'Toilet',
  'Trees & Roots',
  'Washer',
  'Water Features',
  'Water Filters & Softeners',
  'Water Heater',
  'Water Meters & Shutoffs',
  'Window',
  'Yard',
]
  .sort()
  .map((appliance) => ({
    key: appliance,
    value: appliance,
  }));

export const urgency = [
  {
    label: formatString("Next time{br}you're around{smallText}", {
      br: <br />,
      smallText: <div className={cx('small-text')}>3-4 Weeks</div>,
    }),
    key: 'Routine',
  },
  {
    label: formatString('Soon{smallText}', {
      smallText: <div className={cx('small-text')}>1-2 Weeks</div>,
    }),
    key: 'Urgent',
  },
  {
    label: formatString('Emergency{smallText}', {
      smallText: <div className={cx('small-text')}>ASAP</div>,
    }),
    key: 'Emergency',
  },
];

export const requestorButtons = [
  {
    label: formatString('I want to be there{smallText}', {
      smallText: <div className={cx('small-text')}>(To Meet The Pro)</div>,
    }),
    key: true,
  },
  {
    label: formatString("I don't need to be there{smallText}", {
      smallText: <div className={cx('small-text')}>(With 24 Hour Notice)</div>,
    }),
    key: false,
  },
];

export const frequency = [
  {
    label: 'Yearly',
    key: 'Yearly',
  },
  {
    label: 'Quarterly',
    key: 'Quarterly',
  },
  {
    label: 'Monthly',
    key: 'Monthly',
  },
  {
    label: 'Other',
    key: 'Other',
  },
];

export const responsibility = [
  {
    label: <String string={"<span>Yes, I'm</span> responsible"} />,
    key: 'true',
  },
  {
    label: <String string="No, it broke <span>on its own</span>" />,
    key: 'false',
  },
];

export const businessEntities = [
  {
    value: 'C Corporation',
    key: BusinessEntityTypes.CCorporation,
  },
  {
    value: 'S Corporation',
    key: BusinessEntityTypes.SCorporation,
  },
  {
    value: 'Partnership',
    key: BusinessEntityTypes.Partnership,
  },
  {
    value: 'Trust / Estate',
    key: BusinessEntityTypes.TrustOrEstate,
  },
  {
    value: 'Limited Liability Company',
    key: BusinessEntityTypes.LLC,
  },
  {
    value: 'Other',
    key: BusinessEntityTypes.Other,
  },
];

export const llcTaxClassifications = [
  {
    value: 'Single-Member LLC',
    key: LLCTaxClassification.SingleMemberLLC,
  },
  {
    value: 'C Corporation',
    key: LLCTaxClassification.CCorporation,
  },
  {
    value: 'S Corporation',
    key: LLCTaxClassification.SCorporation,
  },
  {
    value: 'Partnership',
    key: LLCTaxClassification.Partnership,
  },
];

export const disclosures = [
  { name: Disclosures.ConstructedBefore1978, title: 'My home was constructed before <span>January 1, 1978.</span>' },
  {
    name: Disclosures.LeadPaint,
    title: 'I have knowledge of lead-based paint or lead-based <span>paint hazards.</span>',
  },
  {
    name: Disclosures.LeadPaintTreatment,
    title: 'I have knowledge of any lead-based paint-related treatment.',
  },
  {
    name: Disclosures.LeadPaintReports,
    title: 'I have reports or records pertaining to lead-based paint or lead-based <span>paint hazards.</span>',
  },
  { name: Disclosures.Mold, title: 'I have knowledge <span>of mold.</span>' },
  { name: Disclosures.MoldTreatment, title: 'I have knowledge of any mold-related treatment.' },
  {
    name: Disclosures.MoldReports,
    title: ' I have reports or records pertaining to mold or <span>mold hazards.</span>',
  },
  { name: Disclosures.Asbestos, title: 'I have knowledge <span>of asbestos.</span>' },
  { name: Disclosures.AsbestosTreatment, title: 'I have knowledge of any asbestos-related treatment.' },
  {
    name: Disclosures.AsbestosReports,
    title: 'I have reports or records pertaining to asbestos or <span>asbestos hazards.</span>',
  },

  { name: Disclosures.Death, title: 'I have knowledge of a death on premises in last <span>3 years.</span>' },
  { name: Disclosures.SharedGasMeters, title: 'This home shares a gas meter <span>with others.</span>' },
  { name: Disclosures.SharedElectricMeters, title: 'This home shares an electric meter <span>with others.</span>' },
  { name: Disclosures.SharedWaterMeters, title: 'This home shares a water meter <span>with others.</span>' },
  {
    name: Disclosures.MilitaryOrdnanceNearby,
    title: 'I have knowledge of a former federal or state ordnance location within one mile of <span>the home.</span>',
  },
  {
    name: Disclosures.IntentionToDemolishHome,
    title: 'I have applied for a permit to demolish <span>the home.</span>',
  },
  {
    name: Disclosures.NoWaterConservingPlumbing,
    title: 'I have not installed water-conserving plumbing fixtures (toilets, <span>showerheads, etc.)</span>',
  },
  {
    name: Disclosures.IsLocatedNearFloodZone,
    title:
      'I have received notice from a public agency that the property is located in a special flood hazard zone or an area of potential flooding.',
  },
  {
    name: Disclosures.MortgageRequiresFloodInsurance,
    title: 'My mortgage holder requires me to carry <span>flood insurance</span>.',
  },
  { name: Disclosures.HasFloodInsurance, title: 'I currently carry flood insurance for this home.' },
  { name: Disclosures.OtherMaterialFacts, title: 'There are other material facts I <span>should disclose.</span>' },
  { name: Disclosures.IsIn100YearFloodPlain, title: 'My home sits on a 100 year floodplain.' },
  { name: Disclosures.FloodedInLast5Years, title: 'My home was flooded in the last 5 years.' },
  {
    name: Disclosures.FloodedAtLeastThreeTimesInLast5Years,
    title: 'My home was flooded at least 3 times in the last 5 years.',
  },
  { name: Disclosures.HasSepticSystem, title: 'My home has a septic system.' },
  { name: Disclosures.HasPublicSewage, title: 'My home has a public sewage.' },
];
